

export class CustomerInfo{
    CNPJ: string;
    ieCli: string;
    nomeCli: string;
    nomeFantasiaCli: string;
    emailCli: string;
    pessoa_id: string;

}
