import { LogService } from './../../_services/log.service';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from '../../_services/authentication.service';
import { AppService } from 'app/_services/app.service';
import { Login } from '../../_models/login';
import { LogFrontFuncionalidade } from 'app/_enums/log-front-funcionalidade';
import { Operacao } from 'app/_enums/operacao';

declare var ocultarControleMenu: any;

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
  exportAs: 'child'
})
export class LoginModalComponent implements OnInit {

  tokenExpirado: boolean = false;
  labelLinkResetSenha: string = 'Esqueci minha senha';

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private _appService: AppService,
    private _logService: LogService) { }

  ngOnInit() {
    this.authenticationService.emitirModal.subscribe(
      modal => this.showModal(modal)
    );
  }

  @ViewChild('lgModal', {static: true}) lgModal;

  public showModal(modal): void {
    if (modal) {
      //this.tokenExpirado = this.authenticationService.tokenExpirado;
      this.tokenExpirado = this._appService.tokenExpirado;
      this.lgModal.show();
    }
    else
      this.lgModal.hide();
  }

  public login: Login = new Login();
  error = '';
  loading = false;

  efetuarLogin() {
    this.loading = true;
    this.authenticationService.login(this.login)
      .subscribe(result => {
        this.error = '';
        if (result.IsValid) {
          this.login = new Login();
          this.authenticationService.hideModal();

          let usuario = this._appService.UsuarioLogado;
          let descricaoPerfis = ["Cliente Master", "Cliente Tracking", "Cliente Booking e Rastreamento", "Cliente Financeiro Operacional"];
          if (descricaoPerfis.some(perfil => perfil === usuario.Perfil.Descricao))
            this.router.navigate(['/rastreamento']);
          else
            this.router.navigate(['/home']);


          this._appService.tokenExpirado = false;
          ocultarControleMenu();
        } else {
          if(result.Erros[0].ErrorCode.includes("VND10")){

            this.error = "Usuário não encontrado. Para acesso de Transportador, favor acessar o site " +  "<a href='" + 
            this._appService.configInfo.novoSiteVendor + "' target='_blank'>" + this._appService.configInfo.novoSiteVendor + "</a>" ;
            this.labelLinkResetSenha = 'Esqueci minha senha';
            this._appService.tokenExpirado = true;
            this._logService.logFront(this.login.username, LogFrontFuncionalidade.Login, this.error, Operacao.Login, "LOGIN");


          }else{

            this.error = result.Erros[0].ErrorCode + ' ' + result.Erros[0].Message;
            this.labelLinkResetSenha = (result.Erros[0].Message == 'Senha expirada') ? 'Clique aqui para Trocar / Reativar sua senha' : 'Esqueci minha senha';
            this._appService.tokenExpirado = true;
            this._logService.logFront(this.login.username, LogFrontFuncionalidade.Login, this.error, Operacao.Login, "LOGIN");
          }

        }
        this.loading = false;
      },
      err => {
        this.error = err;
        this.loading = false;
      });
  }

  ResetSenha() {
    this.authenticationService.hideModal();
    this.router.navigate(['/resetsenha']);
  }
}
